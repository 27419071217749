<template>
  <tiptap-vuetify
    v-model="text"
    :extensions="extensions"
    :placeholder="`${$t('lbl.description')}...`"
    :disabled="$store.state.app.onlyShow"
    @input="setText"
  />
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  TiptapVuetify,
  Bold,
  Italic,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      isLoading: true,
      extensions: [BulletList, OrderedList, ListItem, Italic, Bold, HardBreak],
      text: null,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      textEnriquecido: state => state.app.textEnriquecido,
    }),
  },
  created() {
    this.text = this.textEnriquecido
  },
  methods: {
    ...mapMutations(['updateCMS']),
    setText() {
      this.updateCMS(this.text)
    },
  },
}
</script>
